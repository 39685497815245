<template>

  <main class="prompt-comparison p-3">
    <h2>Compare Prompts</h2>
    <div class="row gap-1">
      <div class="prompt1 col p-2 rounded section">
        <div>
          <div class="form-group">
            <select class="input-group" v-model="prompt1">
              <option :value="null">-- Select Prompt --</option>
              <option :value="p" v-for="(p, i) in prompts" :key="i">
                {{ p }}
              </option>
            </select>
          </div>
        </div>
        <MessageContainer :messages="prompt1Messages"></MessageContainer>
      </div>
      <div class="prompt2 col p-2 rounded section">
        <div class="form-group">
          <select class="input-group" v-model="prompt2">
            <option :value="null">-- Select Prompt --</option>
            <option :value="p" v-for="(p, i) in prompts" :key="i">
              {{ p }}
            </option>
          </select>
        </div>
        <MessageContainer :messages="prompt2Messages"></MessageContainer>
      </div>

      <div class="row">
        <div class="col">
          <UserInput :is_loading="is_loading" @ask="sendUserMessage"></UserInput>
        </div>
      </div>
    </div>
  </main>


</template>
<script setup lang="ts">

import {useStore} from "vuex";
import {ref} from "vue";
import {host} from "@/api/conversation";
import {Message} from "@/types";
import MessageContainer from "@/components/Chat/MessageContainer.vue";
import UserInput from "@/components/Chat/UserInput.vue";

const store = useStore();

const memoryKey = ref<string>('comparison_' + Math.floor(Math.random() * 100000).toString());
const prompts = ref<string[]>([]);
const prompt1 = ref<string | null>(null);
const prompt2 = ref<string | null>(null);

const prompt1Messages = ref<Message[]>([])
const prompt2Messages = ref<Message[]>([]);

const is_loading = ref(false);

fetch(host('/prompts'), {
  method: 'GET',
})
    .then(response => response.json())
    .then(data => {
      console.log(data);
      prompts.value = data;
    })

const sendUserMessage = (message: string) => {

  console.log('get messages', prompt1.value, prompt2.value, message)

  const url = host('/compare/prompts');
  const data = {
    prompt1: prompt1.value,
    prompt2: prompt2.value,
    message: message,
    memoryKey: memoryKey.value,
  }

  is_loading.value = true;

  prompt1Messages.value.push({
    id: Math.floor(Math.random() * 10000000),
    role: "user",
    content: message,
  })

  prompt2Messages.value.push({
    id: Math.floor(Math.random() * 10000000),
    role: "user",
    content: message,
  })

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json'
    }
  })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        prompt1Messages.value.push({
          id: Math.floor(Math.random() * 10000000),
          role: "assistant",
          content: data.prompt1.content,
        })
        prompt2Messages.value.push({
          id: Math.floor(Math.random() * 10000000),
          role: "assistant",
          content: data.prompt2.content,
        })
      })
      .finally(() => {
        is_loading.value = false;
      })
}

</script>

<style scoped>
.section {
  height: 75vh;
  overflow-y: scroll;
  border: solid lightblue 1px;
}
</style>