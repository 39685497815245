<script setup lang="ts">

import {computed, ref, defineEmits, defineProps} from "vue";
import {useStore} from "vuex";
import {Message} from "@/types";
import MessageContainer from "@/components/Chat/MessageContainer.vue";
import UserInput from "@/components/Chat/UserInput.vue";

const emit = defineEmits(['sendingMessage', 'messageSent'])

const props = defineProps({
  title: {
    type: String,
    required: true
  },
  messageKey: {
    type: String,
    required: true
  },
  askUrl: {
    type: String,
    required: true
  },
  memoryKey: {
    type: String,
  }
})

const store = useStore()

const is_loading = ref(false)

const messages = computed(() => store.getters.messages(props.messageKey))

const ask = (query: string) => {

  emit('sendingMessage', {
    key: props.messageKey,
    message: query
  })

  is_loading.value = true

  store.commit('addTempUserMessage', {messageKey: props.messageKey, message: query})

  const agentConfig = store.getters.agentConfig

  const msg = {
    message: query,
    memoryKey: props.memoryKey || 'default',
    ...agentConfig
  }

  fetch(props.askUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(msg)
  })
      .then(response => response.json())
      .then(data => {
        console.log('content: ', data)
        const msg: Message = {
          id: Math.floor(Math.random() * 10000000),
          role: 'assistant',
          content: data.message,
        }
        store.commit('addMessage', {messageKey: props.messageKey, message: msg})
      })
      .catch(error => {
        console.error(error)
      })
      .finally(() => {
        is_loading.value = false
        emit('messageSent', {
          messageKey: props.messageKey,
        })
      })
}

</script>

<template>
  <h3 v-text="title"></h3>
  <MessageContainer :messages="messages"></MessageContainer>

  <UserInput :is_loading="is_loading"
             @ask="ask"
  ></UserInput>

</template>

<style scoped lang="scss">

</style>