<script setup lang="ts">
import {defineProps, nextTick, onMounted, ref, watch} from 'vue'
import showdown from 'showdown'
import {VueShowdown} from "vue-showdown";
import {Message} from "@/types";

type Props = {
  message: Message
}

const props = defineProps<Props>()

const converter = new showdown.Converter()

const render = (content: string) => {
  return converter.makeHtml(content)
}

const chartContainer = ref(null)

const chatOptions = ref(null)

const showChart = ref(false)

const renderChart = () => {
  const el = chartContainer.value
  if (el) {
    try {
      const myChart = echarts.init(chartContainer.value);
      myChart.setOption(chatOptions.value!)
    } catch (e) {
      console.error(e)
    }
  }
}

watch(props.message, async (newValue: any) => {

      if (newValue.meta &&
          newValue.meta.toolName === 'chart_generator' &&
          newValue.meta.result &&
          !showChart.value) {

        console.log("result value: ", newValue.meta.result)

        showChart.value = !showChart.value

        chatOptions.value = newValue.meta.result

        await nextTick()

        renderChart()
      }
    }
)


onMounted(async () => {

  if (props.message.meta?.chart) {

    showChart.value = true

    chatOptions.value = props.message.meta.chart

    await nextTick()

    renderChart()

  }
})


</script>

<template>
  <div class="card mb-3 assistant-message">
    <div class="row">
      <div style="width: 80px" class="d-flex justify-content-center align-content-center mt-3">
        <strong>Bot</strong>
      </div>

      <div class="col">
        <div class="notification p-3 border-1 border-primary w-50 text-start border rounded m-3"
             v-if="message.meta?.notification"><strong>{{ message.meta?.notification }}</strong></div>
        <VueShowdown class="card-body text-start" :markdown="message.content"/>

      </div>
    </div>
  </div>
</template>

<style lang="scss">
table, th, td {
  border: 1px solid;
}

.assistant-message .card-body {
  & table,
  & th,
  & td {
    border: 1px solid;
    padding: 5px
  }

  & table {
    max-width: 100%;
    overflow-x: scroll;
    margin-bottom: 1rem;
  }
}

</style>