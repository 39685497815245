import {createStore, Store} from 'vuex'
import {DeltaMessage, Message} from "@/types";
import {InjectionKey} from 'vue'

function randomString(length: number = 8) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export type MessageCollection = Map<string, Message[]>


// define your typings for the store state
export interface State {
    messages: MessageCollection,
    referenceId: string | null,
}

// define injection key
export const key: InjectionKey<Store<State>> = Symbol()

export default createStore({
    state: {
        messages: new Map<string, Message[]>(),
        webhookSecretKey: '',
        agentId: '',
        referenceId: '',
    },
    getters: {
        messages: (state) => (messageKey: string) => {
            return state.messages.get(messageKey) || []
        },
        agentConfig: (state) => {
            return {
                webhookSecretKey: state.webhookSecretKey,
                agentId: state.agentId,
                referenceId: state.referenceId
            }
        }
    },
    mutations: {
        setAgentConfig(state, payload: {
            webhookSecretKey: string,
            agentId: string
        }) {
            state.webhookSecretKey = payload.webhookSecretKey
            state.agentId = payload.agentId
            state.referenceId = "tunning_" + randomString(10)
        },
        setMessages(state, payload: {
            messageKey: string,
            messages: Message[]
        }) {
            state.messages.set(payload.messageKey, payload.messages)
        },
        addMessage(state, payload: {
            messageKey: string,
            message: Message
        }) {
            console.log('add message', payload.messageKey, payload.message)
            const messages = state.messages.get(payload.messageKey) || []
            messages.push(payload.message)
            state.messages.set(payload.messageKey, messages)
        },
        updateMessage(state, payload: {
            messageKey: string,
            message: Message
        }) {
            const messages = state.messages.get(payload.messageKey) || []
            const index = messages.findIndex(m => m.id === payload.message.id)
            if (index === -1) {
                messages[index] = payload.message
                state.messages.set(payload.messageKey, messages)
            }
        },
        updateLatestMessage(state, payload: {
            messageKey: string,
            message: DeltaMessage
        }) {
            const messages = state.messages.get(payload.messageKey) || []
            const latest = messages[messages.length - 1]
            latest.content = latest.content + payload.message.deltaMessage
            latest.meta = payload.message.meta
        },
        removeLastMessage(state, payload: {
            messageKey: string
        }) {
            const messages = state.messages.get(payload.messageKey) || []
            messages.pop()
            state.messages.set(payload.messageKey, messages)
        },
        addTempUserMessage(state, payload: {
            messageKey: string,
            message: string
        }) {
            const messages = state.messages.get(payload.messageKey) || []
            const id = Math.floor(Math.random() * 100000)
            messages.push({id, role: 'user', content: payload.message})
        },
        resetMessages(state, messageKey: string) {
            state.messages.set(messageKey, [])
        },
        resetReferenceId(state, messageKey: string) {
            state.referenceId = "tunning_" + randomString(10)
        }
    },
    actions: {
        fetchMessages(context, payload: {
            url: string,
            messageKey: string
        }) {

            const _url = `${payload.url}?` + new URLSearchParams({
                agentId: context.state.agentId,
                webhookSecretKey: context.state.webhookSecretKey,
            })
            return fetch(_url)
                .then(response => response.json())
                .then(data => {
                    context.commit('setMessages', {
                        messageKey: payload.messageKey,
                        messages: data.messages
                    })
                })
                .catch((error) => {
                    console.error('Error:', error);
                })

        }
    },
    modules: {}
})
