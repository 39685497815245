const _url = 'https://agent-tuning.demo.aws.yoov.dev/api'
// const _url = 'http://localhost:3000/api'

console.log('_url:', _url)

export const host = (segment: string, params?: Record<string, string>) => {
    const url = `${_url}${segment}`
    if (params) {
        const searchParams = new URLSearchParams(params)
        return `${url}?${searchParams.toString()}`
    }
    return url
}