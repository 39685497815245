<script setup lang="ts">

import {defineProps, defineEmits, ref, watch} from 'vue'

type Props = {
  is_loading: boolean,
}

const props = defineProps<Props>()

const emit = defineEmits(['ask'])

const query = ref<string>("")

const ask = () => {
  emit('ask', query.value)
}

// watch is_loading from true to false then set query to empty string
watch(() => props.is_loading, (newVal, oldVal) => {
  if (oldVal && !newVal) {
    query.value = ""
  }
})
</script>

<template>
  <form action="#" class="form position-sticky bottom-0" @submit.prevent="ask">
    <div class="mb-3">
      <div class="input-group mb-3">
        <input type="text" name="message" class="form-control" placeholder="Your message" v-model="query"
               :disabled="is_loading">
        <button class="btn btn-outline-secondary bg-white"
                type="submit"
                id="button-addon2" :disabled="is_loading">
          Send
        </button>
      </div>
    </div>
  </form>
</template>

<style scoped lang="scss">

</style>