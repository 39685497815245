<template>
  <main v-if="!confirm_new_tuning_session" class="p-3">
    <h2>Tuning</h2>
    <div class="row gap-3 d-flex justify-content-center">
      <div class="col-6">
        <form class="form" @submit.prevent="start_tuning">
          <div class="form-group mb-3">
            <label for="agentId">Agent ID</label>
            <input type="text" class="form-control" id="agentId" v-model="agentConfig.agentId" required>
          </div>
          <div class="form-group mb-3">
            <label for="secretKey">Webhook secret key</label>
            <input type="text" class="form-control" id="secretKey" v-model="agentConfig.webhookSecretKey" required>
          </div>
          <div class="form-group mb-3">
            <button type="submit" class="btn btn-primary">Confirm New Training Session</button>
          </div>
        </form>
      </div>
    </div>
  </main>

  <main class="tuning p-3" v-else>
    <h2>
      Tuning
      <button @click="confirmTuning" class="btn btn-success">Confirm Tuning</button>
    </h2>
    <div class="row gap-3">
      <div class="col bg-secondary" style="height:80vh; overflow-y: scroll ">
        <ChatUI title="Agent Builder"
                :ask-url="host('/builder/messages')"
                :memory-key="memoryKey"
                message-key="agent_builder"
                @message-sent="resetTuningAgent"></ChatUI>
      </div>
      <div class="col bg-info" style="height:80vh; overflow-y: scroll ">
        <ChatUI :ask-url="host('/agent/tuning')"
                message-key="tuning"
                title="Testing Agent"
        ></ChatUI>
      </div>
    </div>


  </main>


</template>
<script setup lang="ts">

import {useStore} from "vuex";
import {ref} from "vue";
import {host} from "@/api/conversation";
import ChatUI from "@/components/ChatUI.vue";

const store = useStore();

const agentConfig = ref({
  webhookSecretKey: null,
  agentId: null
})

const memoryKey = ref<string>('default');

const confirm_new_tuning_session = ref(false);

const resetTuningAgent = (paylod: any) => {
  console.log('resetTuningAgent', paylod)
  const url = host('/agent/tuning/reset');
  fetch(url, {
    method: 'POST',
    body: JSON.stringify({memoryKey: memoryKey.value}),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
      .then(response => response.json())
      .then(data => {
        console.log(data);
      })
      .then(() => {
        store.commit('resetMessages', 'tuning');
        store.commit('resetReferenceId', 'tuning');
      })
      .finally(() => {
        memoryKey.value = Math.floor(Math.random() * 1000000).toString();
      })
}

const start_tuning = () => {

  store.commit('setAgentConfig', agentConfig.value);

  memoryKey.value = Math.floor(Math.random() * 1000000).toString();

  const url = host('/agent/tuning/new')

  fetch(url, {
    method: 'POST',
    body: JSON.stringify(store.getters.agentConfig),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
  })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        confirm_new_tuning_session.value = true;
      })
}

const confirmTuning = () => {
  confirm_new_tuning_session.value = false;
}

</script>