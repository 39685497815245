import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import Tuning from '../pages/Tuning.vue'
import PromptComparision from "@/pages/PromptComparision.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        redirect: '/tuning'
    },
    {
        path: '/tuning',
        name: 'tuning',
        component: Tuning
    },
    {
        path: '/comparison',
        name: 'comparison',
        component: PromptComparision
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
